var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientSiteData && _vm.$can("update", "clients")
    ? _c(
        "div",
        [
          _c("b-media", { staticClass: "mb-2" }, [
            _c("h4", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.clientSiteData.sitename) + " ")
            ])
          ]),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("General Information")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Site Name",
                                        "label-for": "sitename"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "sitename",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "sitename",
                                                      readonly: true
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .sitename,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "sitename",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.sitename"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Name",
                                        "label-for": "client_display_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "client_display_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "client_display_name",
                                                      state:
                                                        errors.length > 0
                                                          ? false
                                                          : null,
                                                      readonly: true,
                                                      name:
                                                        "client_display_name"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .client_display_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "client_display_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.client_display_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Address",
                                        "label-for": "address"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "address" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "address" },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .address,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "address",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.address"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Unit",
                                        "label-for": "addressUnit"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "addressUnit" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "addressUnit"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .addressUnit,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "addressUnit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.addressUnit"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "City",
                                        "label-for": "city"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "city" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "city" },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData.city,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "city",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.city"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Country",
                                        "label-for": "country"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "country" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.countryOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      "input-id": "country",
                                                      clearable: false
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .country,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "country",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.country"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "State / Province",
                                        "label-for": "province"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "province" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.provinceOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      "input-id": "province",
                                                      clearable: false
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .province,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "province",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.province"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Postal Code",
                                        "label-for": "postal"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "postal" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "postal" },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .postal,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "postal",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.postal"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Phone",
                                        "label-for": "phone"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "phone" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "phone" },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .phone,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.phone"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Status",
                                        "label-for": "is_active"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "is_active",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.statusOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id": "is_active"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .is_active,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "is_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.is_active"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.clientSiteData.clientServices.guard === true ||
                          _vm.clientSiteData.clientServices.guardtour ===
                            true ||
                          _vm.clientSiteData.clientServices.tenant === true ||
                          _vm.clientSiteData.clientServices
                            .privateinvestigation === true
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-primary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Security Services Settings")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client Type",
                                                "label-for": "clienttype"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "clienttype" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              options:
                                                                _vm.clienttypeOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              "input-id":
                                                                "clienttype",
                                                              clearable: false
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .client_type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "client_type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.client_type"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Region",
                                                "label-for": "regionloc"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "regionloc" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              options:
                                                                _vm.regionLocOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              "input-id":
                                                                "regionloc",
                                                              clearable: false
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .region_loc,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "region_loc",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.region_loc"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Billing Rate",
                                                "label-for": "billrate"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "billrate" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "billrate"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .bill_rate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.clientSiteData,
                                                                  "bill_rate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.bill_rate"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [_vm._v("Location Settings")])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Latitude",
                                            "label-for": "latitude"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "latitude",
                                              rules:
                                                "regex:^([+-]?([0-9]+\\.[0-9]+))$"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "latitude"
                                                        },
                                                        on: {
                                                          change: _vm.updateMap,
                                                          keyup: _vm.updateMap
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.clientSiteData
                                                              .geofence.lat,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.clientSiteData
                                                                .geofence,
                                                              "lat",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "clientSiteData.geofence.lat"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Longitude",
                                            "label-for": "longitude"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "longitude",
                                              rules:
                                                "regex:^([+-]?([0-9]+\\.[0-9]+))$"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "longitude"
                                                        },
                                                        on: {
                                                          change: _vm.updateMap,
                                                          keyup: _vm.updateMap
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.clientSiteData
                                                              .geofence.lon,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.clientSiteData
                                                                .geofence,
                                                              "lon",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "clientSiteData.geofence.lon"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Distance (metres)",
                                            "label-for": "distance"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "distance",
                                              rules: "integer"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "distance"
                                                        },
                                                        on: {
                                                          change: _vm.updateMap,
                                                          keyup: _vm.updateMap
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.clientSiteData
                                                              .geofence
                                                              .distance,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.clientSiteData
                                                                .geofence,
                                                              "distance",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "clientSiteData.geofence.distance"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.clientSiteData.address !== "" &&
                                  _vm.clientSiteData.city !== "" &&
                                  _vm.clientSiteData.province !== "" &&
                                  _vm.clientSiteData.country !== ""
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { md: "6" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: ""
                                              },
                                              on: { click: _vm.lookupAddress }
                                            },
                                            [_vm._v(" Lookup Address ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              typeof _vm.clientSiteData.geofence.lat !==
                                "undefined" &&
                              typeof _vm.clientSiteData.geofence.lon !==
                                "undefined" &&
                              typeof _vm.clientSiteData.geofence.distance !==
                                "undefined" &&
                              _vm.clientSiteData.geofence.lat !== "" &&
                              _vm.clientSiteData.geofence.lon !== "" &&
                              _vm.clientSiteData.geofence.distance !== ""
                                ? _c(
                                    "b-row",
                                    { key: _vm.mapKey, ref: "theMap" },
                                    [
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "l-map",
                                              {
                                                attrs: {
                                                  zoom: _vm.zoom,
                                                  center: _vm.center
                                                }
                                              },
                                              [
                                                _c("l-tile-layer", {
                                                  attrs: { url: _vm.url }
                                                }),
                                                _c("l-marker", {
                                                  attrs: {
                                                    "lat-lng": _vm.markerLatLng
                                                  }
                                                }),
                                                _c("l-circle", {
                                                  attrs: {
                                                    "lat-lng":
                                                      _vm.circle.center,
                                                    radius: _vm.circle.radius,
                                                    color: _vm.circle.color
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.clientSiteData.clientServices.tenant === true
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-primary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [_vm._v("Tenant Settings")])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tenant URL",
                                                "label-for": "tenanturl"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "tenanturl" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "tenanturl"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .tenant.url,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .clientSiteData
                                                                    .tenant,
                                                                  "url",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.tenant.url"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tenant Password",
                                                "label-for": "tenantpassword"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "tenantpassword"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "tenantpassword"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .tenant
                                                                  .password,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .clientSiteData
                                                                    .tenant,
                                                                  "password",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.tenant.password"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tenant Max Permits",
                                                "label-for": "tenantMaxPermits"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "tenantMaxPermits",
                                                  rules: "integer"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "tenantMaxPermits"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .clientSiteData
                                                                  .tenant
                                                                  .max_permits,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .clientSiteData
                                                                    .tenant,
                                                                  "max_permits",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "clientSiteData.tenant.max_permits"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [_c("b-col", [_c("h4", [_vm._v("Notes")])])],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Admin Notes",
                                        "label-for": "adminnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "adminnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "adminnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .notes_admin,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "notes_admin",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.notes_admin"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Notes",
                                        "label-for": "clientnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "clientnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "clientnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .notes_client,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "notes_client",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.notes_client"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Public Notes",
                                        "label-for": "publicnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "publicnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "publicnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientSiteData
                                                          .notes_public,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientSiteData,
                                                          "notes_public",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientSiteData.notes_public"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _vm.$can("update", "clients")
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "primary",
                                            block: "",
                                            type: "submit"
                                          }
                                        },
                                        [_vm._v(" Save Changes ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2226679774
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }