<template>
  <div v-if="clientSiteData && $can('update', 'clients')">

    <!-- Media -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        {{ clientSiteData.sitename }}
      </h4>
    </b-media>

    <!-- Client Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <!-- General Information -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>General Information</h4>
          </b-col>
        </b-row>
        <b-row>
          <!-- Site Name -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Site Name"
              label-for="sitename"
            >
              <validation-provider
                #default="{ errors }"
                name="sitename"
                rules="required"
              >
                <b-form-input
                  id="sitename"
                  v-model="clientSiteData.sitename"
                  :readonly="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Clientname -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Client Name"
              label-for="client_display_name"
            >
              <validation-provider
                #default="{ errors }"
                name="client_display_name"
                rules="required"
              >
                <b-form-input
                  id="client_display_name"
                  v-model="clientSiteData.client_display_name"
                  :state="errors.length > 0 ? false:null"
                  :readonly="true"
                  name="client_display_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Address Info -->
        <b-row>
          <!-- Address -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
              >
                <b-form-input
                  id="address"
                  v-model="clientSiteData.address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Unit -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Unit"
              label-for="addressUnit"
            >
              <validation-provider
                #default="{ errors }"
                name="addressUnit"
              >
                <b-form-input
                  id="addressUnit"
                  v-model="clientSiteData.addressUnit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- City -->
          <b-col
            md="6"
          >
            <b-form-group
              label="City"
              label-for="city"
            >
              <validation-provider
                #default="{ errors }"
                name="city"
              >
                <b-form-input
                  id="city"
                  v-model="clientSiteData.city"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Country -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <validation-provider
                #default="{ errors }"
                name="country"
              >
                <v-select
                  v-model="clientSiteData.country"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  input-id="country"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Province -->
          <b-col
            md="6"
          >
            <b-form-group
              label="State / Province"
              label-for="province"
            >
              <validation-provider
                #default="{ errors }"
                name="province"
              >
                <v-select
                  v-model="clientSiteData.province"
                  :options="provinceOptions"
                  :reduce="val => val.value"
                  input-id="province"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Postal -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Postal Code"
              label-for="postal"
            >
              <validation-provider
                #default="{ errors }"
                name="postal"
              >
                <b-form-input
                  id="postal"
                  v-model="clientSiteData.postal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Phone -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="clientSiteData.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Status -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Status"
              label-for="is_active"
            >
              <validation-provider
                #default="{ errors }"
                name="is_active"
                rules="required"
              >
                <v-select
                  v-model="clientSiteData.is_active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <!-- Security Services Settings -->
        <div v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
          <b-row class="mt-2 mb-1 border-bottom-primary">
            <b-col>
              <h4>Security Services Settings</h4>
            </b-col>
          </b-row>
          <b-row>
            <!-- client type -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Client Type"
                label-for="clienttype"
              >
                <validation-provider
                  #default="{ errors }"
                  name="clienttype"
                >
                  <v-select
                    v-model="clientSiteData.client_type"
                    :options="clienttypeOptions"
                    :reduce="val => val.value"
                    input-id="clienttype"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Region Location -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Region"
                label-for="regionloc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="regionloc"
                >
                  <v-select
                    v-model="clientSiteData.region_loc"
                    :options="regionLocOptions"
                    :reduce="val => val.value"
                    input-id="regionloc"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Billing Rate -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Billing Rate"
                label-for="billrate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="billrate"
                >
                  <b-form-input
                    id="billrate"
                    v-model="clientSiteData.bill_rate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Location Settings -->
        <div>
          <!-- Title -->
          <b-row class="mt-2 mb-1 border-bottom-primary">
            <b-col>
              <h4>Location Settings</h4>
            </b-col>
          </b-row>
          <!-- Location Fields -->
          <b-row>
            <!-- Latitude -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Latitude"
                label-for="latitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="latitude"
                  rules="regex:^([+-]?([0-9]+\.[0-9]+))$"
                >
                  <b-form-input
                    id="latitude"
                    v-model="clientSiteData.geofence.lat"
                    @change="updateMap"
                    @keyup="updateMap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Longitude -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Longitude"
                label-for="longitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="longitude"
                  rules="regex:^([+-]?([0-9]+\.[0-9]+))$"
                >
                  <b-form-input
                    id="longitude"
                    v-model="clientSiteData.geofence.lon"
                    @change="updateMap"
                    @keyup="updateMap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Distance -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Distance (metres)"
                label-for="distance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="distance"
                  rules="integer"
                >
                  <b-form-input
                    id="distance"
                    v-model="clientSiteData.geofence.distance"
                    @change="updateMap"
                    @keyup="updateMap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Button -->
            <b-col
              v-if="clientSiteData.address !== '' && clientSiteData.city !== '' && clientSiteData.province !== '' && clientSiteData.country !== ''"
              md="6"
              class="mt-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                @click="lookupAddress"
              >
                Lookup Address
              </b-button>
            </b-col>
          </b-row>
          <!-- Map -->
          <b-row
            v-if=" typeof clientSiteData.geofence.lat !== 'undefined' &&
              typeof clientSiteData.geofence.lon !== 'undefined' &&
              typeof clientSiteData.geofence.distance !== 'undefined' &&
              clientSiteData.geofence.lat !== '' &&
              clientSiteData.geofence.lon !== '' &&
              clientSiteData.geofence.distance !== ''
            "
            ref="theMap"
            :key="mapKey"
          >
            <b-col>
              <div>
                <l-map
                  :zoom="zoom"
                  :center="center"
                >
                  <l-tile-layer :url="url" />
                  <l-marker :lat-lng="markerLatLng" />
                  <l-circle
                    :lat-lng="circle.center"
                    :radius="circle.radius"
                    :color="circle.color"
                  />
                  <!-- <l-polygon
                    :lat-lngs="polygon.latlngs"
                    :color="polygon.color"
                  /> -->
                </l-map>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Tenant Settings -->
        <div v-if="clientSiteData.clientServices.tenant === true">
          <!-- Title -->
          <b-row class="mt-2 mb-1 border-bottom-primary">
            <b-col>
              <h4>Tenant Settings</h4>
            </b-col>
          </b-row>
          <!-- Fields -->
          <b-row>
            <!-- Tenant URL -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Tenant URL"
                label-for="tenanturl"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tenanturl"
                >
                  <b-form-input
                    id="tenanturl"
                    v-model="clientSiteData.tenant.url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Tenant Password -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Tenant Password"
                label-for="tenantpassword"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tenantpassword"
                >
                  <b-form-input
                    id="tenantpassword"
                    v-model="clientSiteData.tenant.password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Tenant Max Permits -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Tenant Max Permits"
                label-for="tenantMaxPermits"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tenantMaxPermits"
                  rules="integer"
                >
                  <b-form-input
                    id="tenantMaxPermits"
                    v-model="clientSiteData.tenant.max_permits"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Notes -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Notes</h4>
          </b-col>
        </b-row>
        <!-- Field: Admin Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Admin Notes"
              label-for="adminnote"
            >
              <validation-provider
                #default="{ errors }"
                name="adminnote"
              >
                <b-form-textarea
                  id="adminnote"
                  v-model="clientSiteData.notes_admin"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Client Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Client Notes"
              label-for="clientnote"
            >
              <validation-provider
                #default="{ errors }"
                name="clientnote"
              >
                <b-form-textarea
                  id="clientnote"
                  v-model="clientSiteData.notes_client"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Public Notes -->
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Public Notes"
              label-for="publicnote"
            >
              <validation-provider
                #default="{ errors }"
                name="publicnote"
              >
                <b-form-textarea
                  id="publicnote"
                  v-model="clientSiteData.notes_public"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-row class="mt-3">
          <b-col
            v-if="$can('update', 'clients')"
            md="2"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required, email } from '@validations'
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import {
  LMap, LTileLayer, LMarker, LCircle,
  // LPolygon,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import vSelect from 'vue-select'
import axios from 'axios'
import ability from '../../../../libs/acl/ability'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    // LPolygon,
  },
  props: {
    clientSiteData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // validation rules
      required,
      email,
      // Map
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 19,
      center: [43.7644292, -79.4850433],
      markerLatLng: [43.7644292, -79.4850433],
      circle: {
        center: [43.7644292, -79.4850433],
        radius: 80,
        color: '#EA5455',
      },
      mapKey: 0,

      // polygon: {
      //   latlngs: [[47.2263299, -1.6222], [47.21024000000001, -1.6270065], [47.1969447, -1.6136169], [47.18527929999999, -1.6143036], [47.1794457, -1.6098404], [47.1775788, -1.5985107], [47.1676598, -1.5753365], [47.1593731, -1.5521622], [47.1593731, -1.5319061], [47.1722111, -1.5143967], [47.1960115, -1.4841843], [47.2095404, -1.4848709], [47.2291277, -1.4683914], [47.2533687, -1.5116501], [47.2577961, -1.5531921], [47.26828069, -1.5621185], [47.2657179, -1.589241], [47.2589612, -1.6204834], [47.237287, -1.6266632], [47.2263299, -1.6222]],
      //   color: '#7367F0',
      // },
    }
  },
  setup() {
    const statusOptions = store.state.appSettings.is_active
    const countryOptions = store.state.appSettings.countries
    const provinceOptions = store.state.appSettings.provinces
    const clienttypeOptions = store.state.appSettings.client_type
    const regionLocOptions = store.state.appSettings.regions_loc

    return {
      statusOptions,
      countryOptions,
      provinceOptions,
      clienttypeOptions,
      regionLocOptions,
    }
  },
  created() {
    this.updateMap()
  },
  methods: {
    updateMap() {
      if (typeof this.clientSiteData.geofence.lat !== 'undefined' && typeof this.clientSiteData.geofence.lon !== 'undefined' && typeof this.clientSiteData.geofence.distance !== 'undefined') {
        console.log('Map Updated')
        this.center = [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon]
        this.markerLatLng = [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon]
        this.circle = {
          center: [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon],
          // eslint-disable-next-line radix
          radius: parseInt(this.clientSiteData.geofence.distance),
          color: '#EA5455',
        }
      }
    },
    validateForm() {
      if (ability.can('update', 'clients')) {
        // Temporary Add: Adding additional fields to data set that will be included in for at later date
        this.clientSiteData.privatelabelname = ''
        // Manual Validations

        this.$refs.observer.validate().then(success => {
          if (success) {
            console.log('form validated')
            console.log(this.clientSiteData)
            store.dispatch('app-client/updateClientSite', this.clientSiteData)
              .then(response => {
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client Site Updated',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Updating Client Site information.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          }
        })
      }
    },
    deleteClientSite() {
      if (this.$can('delete', 'clients')) {
        console.log('delete client site')
        console.log(this.clientSiteData)
        store.dispatch('app-client/deleteClientSite', this.clientSiteData)
          .then(response => {
            console.log(response)
            const nextRout = { name: 'apps-clients-view', params: { clientname: this.clientSitesData.clientname } }
            // Route client
            this.$router.replace(nextRout)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client Site Delete',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Deleteing Client Site.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    lookupAddress() {
      if (this.clientSiteData.address !== '' && this.clientSiteData.city !== '' && this.clientSiteData.province !== '' && this.clientSiteData.country !== '') {
        axios
          .get(`https://nominatim.openstreetmap.org/search?q=${this.clientSiteData.address},${this.clientSiteData.city},${this.clientSiteData.province},${this.clientSiteData.country}&format=json&polygon=1&addressdetails=0`)
          .then(response => {
            const { data } = response // [{}, {}]
            console.log(response)
            if (data.length > 0) {
              console.log('Found address')
              this.clientSiteData.geofence.lat = data[0].lat
              this.clientSiteData.geofence.lon = data[0].lon
              this.clientSiteData.geofence.distance = 80
              setTimeout(() => {
                this.updateMap()
                console.log('force update')
              }, 500)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Unable to Locate Address',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 500px;
  }
}
</style>
